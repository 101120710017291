import React, { Suspense } from 'react';
import RMZ from '../src/images/RMZ6.png';

const LazyImage = React.lazy(() => import('./LazyImage'));

const Custom: React.FC = () => {
    return (
        <div className="sm:flex-col lg:flex p-10">
            <div className="flex-col mr-4">
                <h1 className="title romanStandard text-sm">Custom design</h1>
                <p className="text-justify w-[300px] romanStandard">
                    Our top quality service ensures that your bike looks the way you want
                    it 1st time, our innovative design makes that happen. We can access
                    most manufacturer bikes templates from the 80s bikes right up to the
                    latest bikes; this enables us to reproduce vintage and early model
                    decals for your motocross bike along with the latest designs for your
                    new bike
                </p>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <LazyImage src={RMZ} className="lg:w-1/2 lg:ml-auto pt-10 lg:pt-0" alt="Custom Design Bike" />
            </Suspense>
        </div>
    );
};

export default Custom;
