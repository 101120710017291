import React, { Suspense } from "react";

const LazyImage = React.lazy(() => import("./LazyImage"));

const About: React.FC = () => {
  return (
    <div className="p-10">
      <h1 className="title romanStandard text-sm">About</h1>
      <p className="romanStandard">
        Motographic has a reputation of producing top quality Motocross
        Graphics.
      </p>
      <p className="romanStandard">
        We only use top quality long lasting materials. Thick graphics with
        super aggressive adhesives that are specifically made for Motocross
        bikes.
      </p>
      <p className="romanStandard">
        Our product has been tested for over 10 YEARS to give you super hard
        wearing long lasting product that really sticks at a great price.
      </p>
      <p className="pt-5 romanStandard">
        We print your graphics using the latest digital printer technology to
        give clarity and rich vibrant colours.
      </p>
      <div className="lg:flex gap-5 pt-10">
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImage
            src={require("./images/1.png")}
            className="lg:w-1/3 pb-5 lg:pb-0"
            alt="honda1"
          />
          <LazyImage
            src={require("./images/2.png")}
            className="lg:w-1/3 pb-5 lg:pb-0"
            alt="honda2"
          />
          <LazyImage
            src={require("./images/3.png")}
            className="lg:w-1/3 lg:pr-10 pb-5 lg:pb-0"
            alt="honda3"
          />
        </Suspense>
      </div>
    </div>
  );
};

export default About;
