import React, { Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import LazyImage from './LazyImage';

const View: React.FC = () => {
    const { number } = useParams();
    const imageNumber = parseInt(number!);

    type ImageSources = {
        [key: number]: string;
    };

    const imageSources: ImageSources = {
        1: require("./images/gallery/1.png"),
        2: require("./images/gallery/2.png"),
        3: require("./images/gallery/3.png"),
        4: require("./images/gallery/4.png"),
        5: require("./images/gallery/5.png"),
        6: require("./images/gallery/6.png"),
        7: require("./images/gallery/7.png"),
        8: require("./images/gallery/8.png"),
        9: require("./images/gallery/9.png"),
        10: require("./images/gallery/10.png"),
        11: require("./images/gallery/11.png"),
        12: require("./images/gallery/12.png"),
        13: require("./images/gallery/13.png"),
        14: require("./images/gallery/14.png"),
        15: require("./images/gallery/15.png"),
        16: require("./images/gallery/16.png"),
        17: require("./images/gallery/17.png"),
        18: require("./images/gallery/18.png"),
        19: require("./images/gallery/19.png"),
        20: require("./images/gallery/20.png"),
        21: require("./images/gallery/21.png"),
        22: require("./images/gallery/22.png"),
        23: require("./images/gallery/23.png"),
        24: require("./images/gallery/24.png"),
        25: require("./images/gallery/25.png"),
        26: require("./images/gallery/26.png"),
        27: require("./images/gallery/27.png"),
        28: require("./images/gallery/28.png"),
        29: require("./images/gallery/29.png"),
        30: require("./images/gallery/30.png"),
        31: require("./images/gallery/31.png"),
        32: require("./images/gallery/32.png"),
        33: require("./images/gallery/33.png"),
        34: require("./images/gallery/34.png"),
        35: require("./images/gallery/35.png"),
        36: require("./images/gallery/36.png"),
        37: require("./images/gallery/37.png"),
        38: require("./images/gallery/38.png"),
        39: require("./images/gallery/39.png"),
    };

    const placeholderImage = "https://via.placeholder.com/600x400.png?text=Image+Not+Found";
    const imageSrc = imageSources[imageNumber] || placeholderImage;

    return (
        <Link to="/gallery">
            <div className="px-20">
                <Suspense fallback={<div>Loading...</div>}>
                    <LazyImage
                        className="p-2 lg:px-40 lg:pt-2"
                        src={imageSrc}
                        alt={`Gallery Image ${imageNumber}`}
                    />
                </Suspense>
            </div>
        </Link>
    );
};

export default View;
