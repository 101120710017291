import React, { PropsWithChildren } from "react";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

const MainLayout: React.FC<PropsWithChildren<{ navbar?: JSX.Element }>> = ({
  children,
  navbar,
}) => {
  const location = useLocation();
  const isGalleryPage = location.pathname === "/gallery";
  const isAboutPage = location.pathname === "/about";
  const isViewPage = location.pathname.startsWith("/view/");

  return (
    <div className="w-full h-screen flex flex-grow justify-stretch flex-col items-center lg:p-10">
      {navbar}
      <main
        className={clsx("w-full h-full relative bg-grey-600 text-white", {
          "overflow-hidden": !isGalleryPage,
          "overflow-y-auto": isAboutPage,
        })}
      >
        {children}
      </main>
      {!(isGalleryPage || isViewPage) && <Footer />}
    </div>
  );
};

export default MainLayout;
