import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const LazyImage = React.lazy(() => import("./LazyImage"));

const Gallery: React.FC = () => {
  const images = Array.from({ length: 39 }, (_, i) => i + 1).map((num) => ({
    src: require(`./images/gallery/${num}.png`),
    alt: `${num}`,
    to: `/view/${num}`,
  }));

  return (
    <div className="flex-col lg:pb-10">
      <div className="bg-grey-600 px-10 pt-10">
        <h1 className="title romanStandard text-sm">Gallery</h1>
        <p className="pb-10 romanStandard">A small selection of our work</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
          {images.map(({ src, alt, to }) => (
            <Link className="px-5" to={to} key={alt}>
              <Suspense fallback={<div>Loading...</div>}>
                <LazyImage src={src} alt={alt} />
              </Suspense>
            </Link>
          ))}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Gallery;
