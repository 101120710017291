import { Suspense } from "react";
import React from "react";

const LazyImage = React.lazy(() => import('./LazyImage'));

const Home = () => {
  return (
    <div className="p-10 text-center flex flex-col items-center">
      <h1
        className="uppercase romanBold text-lg py-10 bold lg:text-4xl"
        style={{ letterSpacing: "0.3em" }}
      >
        Welcome to
      </h1>
      {/* <img
        src={require("./images/logoOne.png")}
        className="w-1/3 py-10"
        alt="logoTwo"
      /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <LazyImage
          src={require("./images/logoOne.png")}
          alt="logoOne"
          className="w-1/3 py-10"
        />
      </Suspense>
    </div>
  );
};

export default Home;
