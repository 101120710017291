import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './MainLayout';
import Home from './Home';
import About from './About';
import Custom from './Custom';
import Gallery from './Gallery';
import Contact from './Contact';
import NavBar from './NavBar';
import View from './View';

function App() {
  return (
    <Router>
      <MainLayout navbar={<NavBar />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/custom" element={<Custom />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/view/:number" element={<View />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
