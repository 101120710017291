import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="w-full flex mb-3 p-1 bg-riskcomfort-500 text-white">
      <div className="flex items-center">
        <NavLink to="/" className="px-4 romanStandard lg:pl-20">
          Home
        </NavLink>
        <NavLink
          to="/about"
          className="px-4 romanStandard py-0 border-x-2 border-white"
        >
          About
        </NavLink>
        <NavLink to="/custom" className="px-4 romanStandard py-0">
          Custom Design
        </NavLink>
        <NavLink
          to="/gallery"
          className="px-4 romanStandard py-0 border-x-2 border-white"
        >
          Gallery
        </NavLink>
        <NavLink to="/contact" className="px-4 romanStandard py-0">
          Contact
        </NavLink>
      </div>
      <div className="hidden lg:block ml-auto w-[100px] p-2">
        <NavLink to="/">
          <img src={require("./images/logoTwo.png")} alt="logoTwo" />
        </NavLink>
      </div>
    </nav>
  );
};

export default NavBar;
