import clsx from "clsx";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      <p className={clsx("uppercase px-5 pb-10 w-full romanBold pt-5 bg-grey-600 text-center", {
        "text-white": location.pathname === "/",
        "text-riskcomfort-500": location.pathname !== "/"
      })}
      style={{ letterSpacing: "0.1em" }} 
      >
        the home of motocross decals, motocross numbers, motocross graphics &
        motocross bike graphic kits
      </p>
    </>
  );
};

export default Footer;
