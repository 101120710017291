import React, { Suspense } from "react";
import LazyImage from "./LazyImage";

const Contact: React.FC = () => {
  return (
    <div className="p-10">
      <div className="z-10">
        <h1 className="title text-3xl romanStandard text-sm">Contact</h1>
        <p className="romanStandard">Contact us and let's have a chat.</p>
        <p className="romanStandard">Aaron</p>
        <p className="romanStandard">021 607 949</p>
        <p className="romanStandard">
          <a href="mailto:aaron@motographic.co.nz">aaron@motographic.co.nz</a>
        </p>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImage
            src={require("../src/images/logoOne.png")}
            className="w-[150px] pt-5"
            alt="logoOne"
          />
        </Suspense>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyImage
          src={require("../src/images/outline2.png")}
          className="absolute inset-0 w-full h-full object-cover opacity-30"
          alt="outline"
        />
      </Suspense>
    </div>
  );
};

export default Contact;
